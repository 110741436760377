.header-lw {
  max-width: 250px;
  background-color: #f1f4fa;
}

.smooth-transition {
  transition: all 0.6s ease;
}

.header-wr {
  max-width: 100px;
  background-color: #f1f4fa;
  transition: all 0.6s ease;
}

.menu-backdrop-show {
  z-index: 9;
  background: rgba(0, 0, 0, 0.3);
}
.menu-backdrop-hide {
  z-index: -1;
}

.menu_con .backdrop {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.header,
.header-wr,
.menu_con,
.backdrop {
  transition: all 0.8s ease-in-out;
}

.no-rotate {
  transform: rotate(0deg);
}
.rotate-around {
  transform: rotate(-180deg);
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .header-lw,
  .header {
    max-width: 200px;
    width: 200px;
  }
  .menu_con nav div button {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 13px;
    font-weight: 500;
  }
  .menu_con nav div button span img {
    height: 22px !important;
  }
  .menu_con nav div {
    height: 45px !important;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    max-width: 300px;
    width: 300px;
  }
  .header-hide {
    left: -300px;
  }
  .header-show {
    left: 0px;
  }

  .header-wr-hide {
    left: -120px;
  }
  .header-wr-show {
    left: 0px;
  }
  .menu_con {
    width: 100%;
  }
}
