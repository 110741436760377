.call {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #333333;
  color: #fff;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
}

.call .header-container {
  display: flex;
  height: 10vh;
}

.call .header-container .left {
  gap: 30px;
}

.call .header-container .need-help {
  gap: 25px;
}

.call .player-container {
  flex: 1;
  height: 90vh;
}

.call .player-container .videos-con {
  width: 100%;
  text-align: center;
  height: 75vh;
  max-width: calc(100% - 60px);
  margin: 0 auto;
}

.call .player-container .videos-con .inner {
  max-width: 100%;
  width: 100%;
  display: block;
  position: relative;
}

.call .player-container .videos-con .inner .local-video-container,
.call .player-container .videos-con .inner .remote-video-container {
  border-radius: 20px;
  overflow: hidden;
  background-color: #1e1e1e;
  flex: 1;
  position: relative;
}

.call .player-container .videos-con .inner .local-video-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 18vw;
  height: 18vh;
  z-index: 10;
}

.call .player-container .videos-con .inner .remote-video-container {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.call .player-container .videos-con .inner .local-video-container .info-container,
.call .player-container .videos-con .inner .remote-video-container .info-container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 8;
  background-color: #1e1e1e;
}

.remote-user-mic {
  position: absolute;
  top: 25px;
  right: 25px;
  padding: 12px 12px 6px 12px;
  border-radius: 100%;
  background: rgba(60, 60, 60, 0.5);
  z-index: 9;
}

.call .player-container .options-container {
  height: 15vh;
}

.call .player-container .videos-con .inner .info-container .title {
  line-height: 24px;
  font-family: 'Open Sans', sans-serif;
}

.call .player-container .videos-con .inner .info-container div p {
  max-width: 60%;
  margin: 0 auto;
}

.call .videoRefCon {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.call .videoRefConRemote {
  width: 100%;
  height: 100%;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .call .player-container .videos-con .inner {
    width: 100%;
  }

  .call .player-container .videos-con .inner .local-video-container {
    bottom: 3vh;
    left: auto;
    width: 50%;
    height: 30%;
    right: 3vh;
    box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.1);
    border-radius: 14px;
  }

  .call .player-container .videos-con .inner .remote-video-container {
    border-radius: 0px;
  }

  .call .player-container .videos-con .inner .local-video-container h4,
  .call .player-container .videos-con .inner .remote-video-container h4 {
    font-size: 13px;
    line-height: 17px;
  }

  .call .header-container {
    font-size: 11.5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .call .header-container .need-help,
  .call .header-container .left {
    gap: 14px;
    align-items: center;
  }

  .call .player-container .videos-con .inner .remote-video-container .info-container {
    padding-bottom: 20%;
  }

  .call .player-container .videos-con {
    max-width: 100%;
  }
}



#remoteVideoGrid {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  /* justify-content: center; */
  align-items: center;
  overflow: hidden;
}

/* .remote-video-container {
  position: relative;
  width: 25vw;
  aspect-ratio: 4/3;
  max-width: 720px;
  min-width: 360px;
  overflow: hidden;
} */