.chat-container {
  position: fixed;
  top: 0px;
  height: 100%;
  width: 399px;
  border-left: 1px solid #ccc;
  transition: all 0.8s ease-out;
  overflow-y: scroll;
}
.show-chat {
  right: 0px;
}
.hide-chat {
  right: -100%;
}
.chat-container .chat-header {
}
.chat-container .chat-header h4 {
  color: #3c3c3c;
  font-size: 21px;
  line-height: 29px;
  font-weight: 600;
}
.chat-container .chat-header img {
  width: 14px;
}

.chat-container .chat-msgs-con {
  flex: 1;
  height: calc(100vh - 124px);
  overflow: auto;
}

.chat-container .chat-msgs-con .chat-content-header {
}
.chat-container .chat-msgs-con .chat-content-header .clinic-name {
}
.chat-container .chat-msgs-con .chat-content-header .clinic-name .inner {
  background: rgba(241, 136, 0, 0.25);
  border-radius: 8px;
  color: #3c3c3c;
  line-height: 26px;
}
.chat-container .chat-msgs-con .chat-msgs {
  flex: 1;
  justify-content: end;
}

.chat-container .type-msg-container {
  min-height: 70px;
  border-top: 1px solid #d9e1e7;
}

.chat-container .chat-msgs-con .chat-msgs .user-title,
.chat-container .chat-msgs-con .chat-msgs .peer-title {
  color: #3c3c3c;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  font-weight: 600;
}
.chat-container .chat-msgs-con .chat-msgs .peer-title {
  text-align: right;
}
.chat-container .chat-msgs-con .chat-msgs .user-msg,
.chat-container .chat-msgs-con .chat-msgs .peer-msg .inner {
  max-width: 80%;
  border-radius: 15px;
}
.chat-container .chat-msgs-con .chat-msgs .user-msg {
  background: #f5f5f5;
  color: #3c3c3c;
}
.chat-container .chat-msgs-con .chat-msgs .peer-msg {
  justify-content: end;
}
.chat-container .chat-msgs-con .chat-msgs .peer-msg .inner {
  background: #2866a9;
  color: #fff;
}
.chat-container .type-msg-container form {
  width: 100%;
  padding-right: 15px;
}
.chat-container .type-msg-container form .textarea-con {
  flex: 1;
  height: 70%;
  max-height: 100%;
}
.chat-container .type-msg-container form .textarea-con textarea {
  width: 100%;
  resize: none;
  height: 100%;
}
.chat-container .type-msg-container form .textarea-con textarea:focus {
  outline: none;
}
.chat-container .type-msg-container form .chat-form-actions {
  gap: 10px;
}
.chat-container .type-msg-container form .chat-form-actions .submit-icon {
  background-color: #0071eb;
  border-radius: 50%;
  padding: 9px;
}
.chat-container .chat-msgs-con .chat-msgs .peer-msg .img-msg {
  border-radius: 8px;
}
.chat-container .chat-msgs-con .chat-msgs .peer-msg .img-msg .view-con {
  top: 6px;
  right: 6px;
  background: rgba(0, 0, 0, 0.4);
  padding: 6px;
}
.chat-container .chat-msgs-con .chat-msgs .peer-msg .img-msg .view-con img {
}
.chat-container .chat-msgs-con .chat-msgs .peer-msg .img-msg > img {
}
.chat-container .chat-msgs-con .chat-msgs .peer-msg .img-msg {
}

.join-notification {
  background-color: rgba(241, 136, 0, 0.25);
}

@media only screen and (min-width: 1025px) and (max-width: 1249px) {
  .chat-container {
    width: 330px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .chat-container {
    width: 310px;
  }
}

@media only screen and (max-width: 768px) {
  .chat-container {
    z-index: 20;
    width: calc(100% - 28px);
    background: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  }
}
