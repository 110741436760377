.option {
  height: auto;
}
.option .top {
  background-color: rgba(0, 0, 0, 0.86);
  height: 7vh;
  width: 5vw;
  min-width: 45px;
}

.option .top-red {
  background-color: #d65858;
  height: 7vh;
  width: 5vw;
  min-width: 45px;
}

.option .top img {
}

.option span {
}

@media only screen and (max-width: 767px) {
  .option .top {
    width: 45px;
    height: 50px;
  }
}
