.info-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  background-color: #1e1e1e;
}

.name-con {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  background-color: #757575;
  display: flex;
  align-items: center;
  justify-content: center;
}
