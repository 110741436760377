.pre-call-test-header-container {
  height: 10vh;
  background-color: rgba(51, 51, 51, 0.5);
}

.pre-call-test-options-container {
  height: 15vh;
  background-color: rgba(51, 51, 51, 0.5);
}

.pre-call-test-video-background {
  z-index: 1;
  background-color: #333333;
}

@media only screen and (max-width: 767px) {
  .pre-call-test-options-container {
    height: 18vh;
    background-color: rgba(51, 51, 51, 0.5);
  }
}
