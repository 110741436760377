.fr-box {
  position: absolute;
  z-index: 1000;
  bottom: 10px;
  right: 10px;
}

@media only screen and (max-width: 767px) {
  .fr-box {
    max-width: calc(100% - 12px);
    right: 6px;
    bottom: 6px;
  }
}
